import React from "react";
import Card from "react-bootstrap/Card";
import AWSConfigText from "./AWSConfigText";

export default function AWSUserConfig({ links, logonAccount }) {
  return (
    <Card>
      <Card.Title>Client CLI setup instructions</Card.Title>
      <Card.Body>
        <ol>
          <li>
            <div>
              Install aws-google-auth.&nbsp;
              <a
                href="https://github.com/cevoaustralia/aws-google-auth"
                target="_blank"
                rel="noopener noreferrer"
              >
                Full instructions.
              </a>
            </div>
            <code>pip install --user aws-google-auth</code>
          </li>
          <li>Ensure that aws-google-auth is in your PATH.</li>
          <li>
            <p>
              Run this script to add the profiles to your ~/.aws/config file.
            </p>
            <AWSConfigText links={links} logonAccount={logonAccount} />
          </li>
        </ol>
      </Card.Body>
    </Card>
  );
}
