import React from "react";
import PropTypes from "prop-types";
import ShowBashScript from "./ShowBashScript";
import { linkProp } from "./linkProp";
function awsProfile(link, role, logonAccount) {
  const profile = `${link.profilePrefix}-${role}`;
  const sourceProfile = `logon-${logonAccount}`;
  return `
echo ${link.description} profile
  aws configure set source_profile ${sourceProfile} --profile ${profile}
  aws configure set role_arn arn:aws:iam::${link.account}:role/${link.rolePrefix}-${role} --profile ${profile}
  aws configure set region eu-west-1 --profile ${profile}
  aws configure set output json --profile ${profile}
`;
}
export default function AWSConfigText({ links, logonAccount }) {
  const lines = links.map((l) => {
    return (
      awsProfile(l, "admin", logonAccount) +
      awsProfile(l, "developer", logonAccount) +
      awsProfile(l, "support", logonAccount)
    );
  });
  const text = lines.join("\n");
  return <ShowBashScript asText scriptName="addprofiles.bat" value={text} />;
  // return <div />;
}
AWSConfigText.propTypes = {
  links: PropTypes.arrayOf(linkProp).isRequired,
};
