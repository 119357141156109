export const infra_2s = {
  title: "HCare 360 infrastructure",
  google_idp: "C04g15psr",
  google_sp: "649847071096",
  rootAccount: "823227806102",
  logonAccount: "214382974212",
  links: [
    {
      account: "214382974212",
      useRoles: false,
      directLink:
        "https://accounts.google.com/o/saml2/initsso?idpid=C04g15psr&spid=649847071096&forceauthn=false",
      rolePrefix: "",
      description: "Logon with Google",
    },
    {
      account: "823227806102",
      useRoles: false,
      profilePrefix: "hc-root",
      directLink: "https://823227806102.signin.aws.amazon.com/console",
      description: "Login with AWS root account",
    },
    {
      account: "823227806102",
      useRoles: true,
      rolePrefix: "saml-role",
      profilePrefix: "hc-root",
      description: "HC360 Root",
    },

    {
      account: "151404439009",
      useRoles: true,
      rolePrefix: "saml-role",
      profilePrefix: "hc-ihsdev",
      description: "HC360 Development",
    },
    {
      account: "269984425385",
      useRoles: true,
      rolePrefix: "saml-role",
      profilePrefix: "hc-bsxprod",
      description: "HC360 BSX Production",
    },
    {
      account: "747905868571",
      useRoles: true,
      rolePrefix: "saml-role",
      profilePrefix: "hc-bayerdev",
      description: "HC360 Bayer Development",
    },
    {
      account: "247999678123",
      useRoles: true,
      rolePrefix: "saml-role",
      profilePrefix: "hc-bayerprod",
      description: "HC360 Bayer Production",
    },
    {
      account: "369671611598",
      useRoles: true,
      profilePrefix: "hc-mdtprod",
      rolePrefix: "saml-role",
      description: "HC360 MDT Production",
    },
    {
      account: "298014603070",
      useRoles: true,
      rolePrefix: "saml-role",
      profilePrefix: "numantra",
      description: "Numantra Assets",
    },
    {
      account: "281204968434",
      useRoles: true,
      rolePrefix: "saml-role",
      profilePrefix: "2sconsulting",
      description: "2S Consulting Assets",
    },

    {
      account: "214382974212",
      useRoles: true,
      rolePrefix: "saml-role",
      profilePrefix: "hc-logon",
      description: "HC360 Logon",
    },
    {
      account: "478576372372",
      useRoles: true,
      rolePrefix: "saml-role",
      profilePrefix: "num-llc",
      description: "Numantra LLC root",
    },

    {
      account: "831678001367",
      useRoles: true,
      rolePrefix: "saml-role",
      profilePrefix: "phl-prod",
      description: "Philips Prod",
    },
    {
      account: "497227304821",
      useRoles: true,
      rolePrefix: "saml-role",
      profilePrefix: "phl-dev",
      description: "Philips Dev",
    },
    {
      account: "340640776055",
      useRoles: true,
      rolePrefix: "saml-role",
      profilePrefix: "bayus-dev",
      description: "Bayer-US-Dev",
    },
    {
      account: "536442424994",
      useRoles: true,
      rolePrefix: "saml-role",
      profilePrefix: "bayus-prod",
      description: "Bayer-US-prod",
    },
  ],
};
