import React from "react";
import PropTypes from "prop-types";
import { linkProp } from "./linkProp";
import ShowBashScript from "./ShowBashScript";

function createRoleForLevel(link, level, logonAccount, rootAccount) {
  const { account, rolePrefix } = link;
  const roleName = `${rolePrefix}-${level}`;
  const profile = `setup-${account}`;
  const roleARN = `arn:aws:iam::${account}:role/${roleName}`;

  // Select either Administrator or ViewOnly as the permissions for this role.
  let policyARN = "arn:aws:iam::aws:policy/job-function/ViewOnlyAccess";
  if (level === "admin") {
    policyARN = "arn:aws:iam::aws:policy/AdministratorAccess";
  }

  //
  return `
echo ${link.description} ${level}
RN=\`aws iam get-role --role-name ${roleName} --profile ${profile} --query "Role.RoleName"\`
if [ $? -eq 0 ]
then
  echo "  skipping since ${roleName} is available."
else
  echo "  creating the role ${roleName} in account ${account}. "
  echo "    This role can be used from IAM users in logon:${logonAccount} or root:${rootAccount} AWS accounts."
  echo "    Permissions - ${policyARN}"
  cat > trustpolicy-${roleName} <<EOF1
  {
      "Version": "2012-10-17",
      "Statement": [
          {
              "Effect": "Allow",
              "Principal": {
                  "AWS": [
                      "arn:aws:iam::${logonAccount}:root",
                      "arn:aws:iam::${rootAccount}:root"
                  ]
              },
              "Action": "sts:AssumeRole",
              "Condition": {}
          }
      ]
  }
EOF1
  RN=\`aws iam create-role --role-name ${roleName} --assume-role-policy-document file://./trustpolicy-${roleName} --profile ${profile} --query "Role.RoleName"\`
  if [ $? -eq 0 ]
  then
    echo "    ${roleARN}"
    aws iam attach-role-policy --role-name ${roleName} --policy-arn ${policyARN} --profile ${profile}
  else
    echo "  Failed to create the role ${roleName} in account ${account}"
  fi
fi
`;
}
function createAssumeRoleForLevelPolicy(link, level, logonAccount, levels) {
  const { account, rolePrefix, profilePrefix } = link;
  const policyname = `assumerole-${profilePrefix}-${level}`;
  const roles = [];
  roles.push(`arn:aws:iam::${account}:role/${rolePrefix}-${level}`);
  levels.forEach((lev) => {
    roles.push(`arn:aws:iam::${account}:role/${rolePrefix}-${lev}`);
  });
  let roleARN = "";
  if (roles.length === 1) {
    roleARN = `"${roles[0]}"`;
  } else {
    const arns = roles.map((r) => `"${r}"`);
    roleARN = `[${arns.join(",")}]`;
  }
  return `
echo ${link.description} ${level}
echo   Create the policy ${policyname} in the account ${logonAccount}
echo   This permission will let the user or role - switch roles to the role ${roleARN}
cat > ${policyname} <<EOF1
{
    "Version": "2012-10-17",
    "Statement": [
        {
            "Sid": "1",
            "Effect": "Allow",
            "Action": "sts:AssumeRole",
            "Resource": ${roleARN}
        }
    ]
}
EOF1
aws iam create-policy --policy-name ${policyname} --policy-document file://./${policyname} --profile setup-${logonAccount}
`;
}
function awsProfile(link, rootAccount) {
  if (link.account === rootAccount) return "";
  const profile = `setup-${link.account}`;
  return `
echo ${link.description} profile
  aws configure set source_profile setup-${rootAccount} --profile ${profile}
  aws configure set role_arn arn:aws:iam::${link.account}:role/OrganizationAccountAccessRole --profile ${profile}
  aws configure set region eu-west-1 --profile ${profile}
  aws configure set output json --profile ${profile}`;
}
function createAWSProfiles(links, rootAccount) {
  const lines = links.map((l) => awsProfile(l, rootAccount));
  return "#!/bin/sh\n" + lines.join("\n");
}
function createRoles(links, logonAccount, rootAccount) {
  const lines = links.map((l) => {
    return [
      createRoleForLevel(l, "admin", logonAccount, rootAccount),
      createRoleForLevel(l, "developer", logonAccount, rootAccount),
      createRoleForLevel(l, "support", logonAccount, rootAccount),
    ].join("\n");
  });
  return "#!/bin/sh\n" + lines.join("\n");
}

function createAssumeRolePolicies(links, logonAccount) {
  const lines = links.map((l) => {
    return [
      createAssumeRoleForLevelPolicy(l, "admin", logonAccount, [
        "developer",
        "support",
      ]),
      createAssumeRoleForLevelPolicy(l, "developer", logonAccount, ["support"]),
      createAssumeRoleForLevelPolicy(l, "support", logonAccount, []),
    ].join("\n");
  });
  return "#!/bin/sh\n" + lines.join("\n");
}

function AWSSetupConfigText({ links, rootAccount, logonAccount }) {
  const roleLinks = links.filter((l) => l.useRoles === true);
  const textSetupRoot = `#!/bin/sh\necho Enter the root account key\naws configure --profile setup-${rootAccount}`;
  const textSetupProfiles = createAWSProfiles(roleLinks, rootAccount);
  const textSetupRoles = createRoles(roleLinks, logonAccount, rootAccount);
  const textSetupPolicies = createAssumeRolePolicies(roleLinks, logonAccount);
  const textCombined = [
    textSetupRoot,
    textSetupProfiles,
    textSetupRoles,
    textSetupPolicies,
  ].join("\n");
  return (
    <>
      <p>
        Combined script with all the steps.
        <br />
        <ShowBashScript asText scriptName="combined.sh" value={textCombined} />
      </p>
      <p>Individual separate steps.</p>
      <ol>
        <li>
          Configure the AWS keys for the organization root account (
          {rootAccount}
          ).
          <br />
          <ShowBashScript scriptName="step1.sh" value={textSetupRoot} />
        </li>
        <li>
          Create profiles for each account.
          <br />
          <ShowBashScript scriptName="step2.sh" value={textSetupProfiles} />
        </li>

        <li>
          Create roles in each account.
          <br />
          <ShowBashScript asText scriptName="step3.sh" value={textSetupRoles} />
        </li>
        <li>
          Create permissions in the logon account ({logonAccount}) and root
          account ({rootAccount}) to switch roles.
          <br />
          <ShowBashScript
            asText
            scriptName="step4.sh"
            value={textSetupPolicies}
          />
        </li>
      </ol>
    </>
  );
}

function ScriptGenerator(props) {
  return (
    <>
      <h3>Scripts to setup AWS with the policies and roles.</h3>
      <AWSSetupConfigText {...props} />
    </>
  );
}

ScriptGenerator.propTypes = {
  rootAccount: PropTypes.string.isRequired,
  logonAccount: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(linkProp).isRequired,
};

export default ScriptGenerator;
