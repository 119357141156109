export const infra_vmc = {
  title: "van Mahajan infrastructure",
  google_idp: "C034otyy7",
  google_sp: "706520041391",
  rootAccount: "674442712091",
  logonAccount: "264815312529",
  links: [
    {
      account: "264815312529",
      useRoles: false,
      directLink:
        "https://accounts.google.com/o/saml2/initsso?idpid=C034otyy7&spid=706520041391&forceauthn=false",
      rolePrefix: "",
      description: "Logon with Google",
    },
    {
      account: "674442712091",
      useRoles: false,
      profilePrefix: "vmc-root",
      directLink: "https://674442712091.signin.aws.amazon.com/console",
      description: "Login with AWS root account",
    },
    {
      account: "674442712091",
      useRoles: true,
      profilePrefix: "vmc-root",
      rolePrefix: "saml-role",
      description: "VMC Root account",
    },
    {
      account: "264815312529",
      useRoles: true,
      profilePrefix: "vmc-logon",
      rolePrefix: "saml-role",
      description: "VMC Logon account",
    },
    {
      account: "878533711062",
      useRoles: true,
      rolePrefix: "saml-role",
      profilePrefix: "vmc-skype",
      description: "VMC Skypelogs production",
    },
    {
      account: "172912723449",
      useRoles: true,
      rolePrefix: "saml-role",
      profilePrefix: "vmc-prod",
      description: "VMC Production",
    },
    {
      account: "030505448914",
      useRoles: true,
      rolePrefix: "saml-role",
      profilePrefix: "vmc-dev",
      description: "VMC Development",
    },
    {
      account: "852391518417",
      useRoles: true,
      rolePrefix: "saml-role",
      profilePrefix: "vmc-philips",
      description: "Philips original",
    },
  ],
};
