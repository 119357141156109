import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";

import "./App.css";
import Container from "react-bootstrap/Container";
import { Helmet } from "react-helmet";

import LaunchTable from "./LaunchTable";
import { infra_vmc } from "./infra_vmc";
import { infra_2s } from "./infra_2s";
import { GoogleLogout, GoogleLogin } from "react-google-login";

const version = require("./version.json");
let defaultGoogleClient_Localhost =
  "1011282394544-q7uvs7rditdrqcpei9e401cloba569bd.apps.googleusercontent.com";
// Display version on console.
// eslint-disable-next-line no-console

function App() {
  const [loggedIn, setloggedIn] = useState(false);
  const [profile, setProfile] = useState(null);
  const [email, setEmail] = useState(null);
  let clientID = process.env.REACT_APP_GCLIENT || defaultGoogleClient_Localhost;
  let hostedDomain = null;
  const logoutAction = () => {
    setloggedIn(false);
    setProfile(null);
    setEmail(null);
  };
  const loginSuccess = (code) => {
    const p = code.getBasicProfile();
    setloggedIn(true);
    setProfile(p);
    setEmail(p.getEmail());
  };
  const loginFailure = (error, details) => {
    console.table(error);
    console.table(details);
    setloggedIn(false);
  };
  const year = new Date().getFullYear();
  const semverString = version.semverString || version.suffix;
  console.log(
    `Version:${semverString}\nInfrastructure:${process.env.REACT_APP_INFRA}`
  );
  const copyright = `${year} Copyright VM Consulting (${semverString}).`;
  const title = "Launch page";

  let infra = infra_2s;
  // Allows building different version based on environment variable at build time.
  // REACT_APP_INFRA=2s yarn start
  if (process.env.REACT_APP_INFRA === "2s") {
    infra = infra_2s;
    hostedDomain = "2sconsulting.com";
  }
  if (process.env.REACT_APP_INFRA === "vmc") {
    infra = infra_vmc;
    hostedDomain = "vanmahajan.com";
  }
  const logoutButton = (
    <GoogleLogout
      clientId={clientID}
      buttonText="Logout"
      onLogoutSuccess={logoutAction}
    />
  );
  const loginContent = (
    <div>
      <h1>{title}</h1>
      <p>Please logon with your Google account.</p>
      <GoogleLogin
        clientId={clientID}
        buttonText="Login"
        onSuccess={loginSuccess}
        onFailure={loginFailure}
        cookiePolicy={"single_host_origin"}
        hostedDomain={hostedDomain}
      />
    </div>
  );
  const launchTable = (
    <LaunchTable {...infra} {...{ email, profile, logoutButton }} />
  );
  const content = loggedIn ? launchTable : loginContent;
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Container className="p-3">{content}</Container>
      <div className="footer-copyright text-center py-3">
        &copy; {copyright}
      </div>
    </>
  );
}

export default App;
