import React from "react";
import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";
import SyntaxHighlighter from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";
import Button from "react-bootstrap/Button";
export default function ShowBashScript({ value, scriptName, asText }) {
  const downloadTxtFile = () => {
    const element = document.createElement("a");
    const file = new Blob([value], { type: "text/plain" });
    element.href = URL.createObjectURL(file);
    element.download = scriptName || "script.sh";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };
  return (
    <>
      <Button className="mb-1" onClick={downloadTxtFile}>
        Download script
      </Button>
      {asText ? (
        <Form.Control as="textarea" rows="5" readOnly value={value} />
      ) : (
        <SyntaxHighlighter language="bash" style={docco}>
          {value}
        </SyntaxHighlighter>
      )}
    </>
  );
}
ShowBashScript.propTypes = {
  asText: PropTypes.bool,
  scriptName: PropTypes.string,
  value: PropTypes.string.isRequired,
};
