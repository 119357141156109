import React from "react";

import PropTypes from "prop-types";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import CardColumns from "react-bootstrap/CardColumns";
import CardGroup from "react-bootstrap/CardGroup";
import ScriptGenerator from "./ScriptGenerator";
import { linkProp } from "./linkProp";
import LogonInstructions from "./LogonInstructions";
import AWSUserConfig from "./AWSUserConfig";
function DirectLink({ link }) {
  return (
    <Card style={{ width: "18rem" }}>
      <Card.Body>
        <Card.Title>{link.description}</Card.Title>
        <Card.Text>{link.account}</Card.Text>
        <Button target="_blank" href={link.directLink} variant="primary">
          Logon
        </Button>
      </Card.Body>
    </Card>
  );
}
function urlForRole(link, role, level) {
  return `https://signin.aws.amazon.com/switchrole?roleName=${role}&account=${
    link.account
  }&displayName=${encodeURI(link.description + " " + level)}`;
}
function SwitchRoleLink({ link }) {
  const adminLink = urlForRole(link, `${link.rolePrefix}-admin`, "admin");
  const developerLink = urlForRole(
    link,
    `${link.rolePrefix}-developer`,
    "developer"
  );
  const supportLink = urlForRole(link, `${link.rolePrefix}-support`, "support");
  const orgAccessLink = urlForRole(
    link,
    `OrganizationAccountAccessRole`,
    "orgAccess"
  );

  return (
    <Card style={{ width: "20rem" }}>
      <Card.Body>
        <Card.Title>{link.description}</Card.Title>
        <Card.Text>{link.account}</Card.Text>
        <Button
          href={orgAccessLink}
          target="_blank"
          className="m-1"
          variant="danger"
        >
          Org Access
        </Button>
        <Button
          href={adminLink}
          target="_blank"
          className="m-1"
          variant="danger"
        >
          Admin
        </Button>
        <Button
          href={developerLink}
          target="_blank"
          className="m-1"
          variant="info"
        >
          Developer
        </Button>
        <Button
          href={supportLink}
          target="_blank"
          className="m-1"
          variant="info"
        >
          Support
        </Button>
      </Card.Body>
    </Card>
  );
}
export function ProfileTable({ links }) {
  return links.map((l, idx) => {
    return (
      <React.Fragment key={idx}>
        <span className="font-weight-bold">{l.description}: &nbsp;</span>
        <span className="bg-secondary text-white m-1 p-1">
          {l.profilePrefix}-admin
        </span>
        <span className="bg-secondary text-white m-1 p-1">
          {l.profilePrefix}-developer
        </span>
        <span className="bg-secondary text-white m-1 p-1">
          {l.profilePrefix}-support
        </span>
        <br />
      </React.Fragment>
    );
  });
}

function LaunchTable({
  title,
  links,
  google_idp,
  google_sp,
  profile_root,
  rootAccount,
  logonAccount,
  email,
  logoutButton,
}) {
  const directLinks = links.filter((l) => l.useRoles === false);
  const switchRoleLinks = links.filter((l) => l.useRoles === true);
  const directCards = directLinks.map((l, idx) => {
    return <DirectLink key={idx} link={l} />;
  });
  const switchRoleCards = switchRoleLinks.map((l, idx) => {
    return <SwitchRoleLink key={idx} link={l} />;
  });
  return (
    <>
      <h1 className="header">{title}</h1>
      <div>
        <span className="font-weight-bold">{email}</span> {logoutButton}
      </div>
      <div className="mb-2 mt-2">
        Choose a tab for instructions on accessing the AWS web console or using
        the AWS CLI from the command line.
      </div>
      <Tabs defaultActiveKey="browser" id="uncontrolled-tab-example">
        <Tab eventKey="browser" title="AWS Web Console">
          <div className="border p-2">
            <h3>Step 1: Logon in your browser</h3>
            <CardGroup className="m-3">{directCards}</CardGroup>
            <h3>Step 2: Switch roles in your browser</h3>
            <CardColumns>{switchRoleCards}</CardColumns>
          </div>
        </Tab>
        <Tab eventKey="console" title="AWS CLI">
          <div className="border p-2">
            <LogonInstructions
              google_idp={google_idp}
              google_sp={google_sp}
              profile_root={profile_root}
              links={switchRoleLinks}
              logonAccount={logonAccount}
              email={email}
            />
            <AWSUserConfig
              links={switchRoleLinks}
              logonAccount={logonAccount}
            />
            }
          </div>
        </Tab>
        <Tab eventKey="setup" title="Setup AWS">
          <div className="border p-2">
            <ScriptGenerator
              links={links}
              rootAccount={rootAccount}
              logonAccount={logonAccount}
            />
          </div>
        </Tab>
      </Tabs>
    </>
  );
}

DirectLink.propTypes = {
  link: linkProp,
};
SwitchRoleLink.propTypes = {
  link: linkProp,
};
ProfileTable.propTypes = {
  links: PropTypes.arrayOf(linkProp).isRequired,
};
LaunchTable.propTypes = {
  title: PropTypes.string.isRequired,
  google_idp: PropTypes.string,
  google_sp: PropTypes.string,
  profile_root: PropTypes.string,
  rootAccount: PropTypes.string.isRequired,
  logonAccount: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(linkProp).isRequired,
  email: PropTypes.string,
  profile: PropTypes.object,
  logoutButton: PropTypes.any,
};

export default LaunchTable;
