import React from "react";
import Card from "react-bootstrap/Card";
import { ProfileTable } from "./LaunchTable";
export default function LogonInstructions({
  google_idp,
  google_sp,
  links,
  logonAccount,
  email,
}) {
  const sourceProfile = `logon-${logonAccount}`;
  return (
    <Card className="mb-2">
      <Card.Title>Using the AWS CLI from the command line</Card.Title>
      <Card.Body>
        <ol>
          <li>
            Complete the setup instructions below first. Logon with your Google
            email.
            <br />
            <code>
              aws-google-auth -I {google_idp} -S {google_sp} -u {email} -p{" "}
              {sourceProfile}
            </code>
          </li>
          <li>
            Run the AWS command with --profile
            <br />
            <code>aws s3 ls --profile PROFILENAME</code>
          </li>
        </ol>
        List of available profile names:
        <br />
        <ProfileTable links={links} />
      </Card.Body>
    </Card>
  );
}
